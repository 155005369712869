body{/*padding-bottom:40px;*/background-color:#FAFAFA}.zf-green{color:#68b604}.btn-success{background-color:#57a900;background-image:-moz-linear-gradient(top, #70d900, #57a900);background-image:-ms-linear-gradient(top, #70d900, #57a900);background-image:-webkit-gradient(linear, 0 0, 0 100%, from(#70d900), to(#57a900));background-image:-webkit-linear-gradient(top, #70d900, #57a900);background-image:-o-linear-gradient(top, #70d900, #57a900);background-image:linear-gradient(top, #70d900, #57a900);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#70d900', endColorstr='#57a900', GradientType=0)}.btn-success:hover,.btn-success:active,.btn-success.active,.btn-success.disabled,.btn-success[disabled]{background-color:#57a900}.btn-success:active,.btn-success.active{background-color:#57a900}div.container a.brand{background:url("../img/zf2-logo.png") no-repeat scroll 0 10px transparent;margin-left:0;padding:8px 20px 12px 40px}.acount{position:relative;display:inline-block;margin-top:5px;margin-bottom:5px;line-height:18px;font-size:14px;color:#fff;border:1px solid #333;border-bottom-color:#282828;-moz-border-radius:3px;-webkit-border-radius:3px;border-radius:3px;text-align:left;cursor:pointer;padding:5px;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:-o-linear-gradient(top, #454545 0%, #383838 100%);background-image:-moz-linear-gradient(top, #454545 0%, #383838 100%);background-image:-webkit-linear-gradient(top, #454545 0%, #383838 100%);background-image:-ms-linear-gradient(top, #454545 0%, #383838 100%);background-image:linear-gradient(top, #454545 0%, #383838 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#454545', endColorstr='#383838', GradientType=0);box-shadow:inset 0px 1px 1px 0px rgba(255, 255, 255, 0.1);max-width:200px}
/*Second Menu */
.second_menu{
	font:12px/22px 'Open Sans',Helvetica,Arial;
    float: left;
    display: block;
    position: relative;
    margin-top: 15px;
	margin-left: -25px;
    -webkit-transition: height 0s, opacity 300ms ease-in-out;
    -moz-transition: height 0s, opacity 300ms ease-in-out;
    -ms-transition: height 0s, opacity 300ms ease-in-out;
    -o-transition: height 0s, opacity 300ms ease-in-out;
    transition: height 0s, opacity 300ms ease-in-out;
    opacity: 1;
}
.second_menu a {
    color: #06F;
    text-decoration: none;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li{
    display: inline;
	border: 0 !important;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li a:after{
    content: " | ";
    color: #b6b6b6;
    margin: 0px 5px;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li >a{
    color: #b6b6b6;
    font-size: 13px;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li >a:hover{
    color: #002CB2;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li:first-child a{
    color: #1971ff;
    font-weight: 600;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li:last-child a:after{
    content: "";
}
ol, ul {
	list-style:none;
	border:0;
}
.orangebar{
	position: absolute;
    float: right;
    padding-left: 225px;
    margin-top: 18px;
	}
	
input#identity.rounded {

  border: 1px solid #ccc;
   
  /* Safari 5, Chrome support border-radius without vendor prefix.
   * FF 3.0/3.5/3.6, Mobile Safari 4.0.4 require vendor prefix.
   * No support in Safari 3/4, IE 6/7/8, Opera 10.0.
   */
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  
  /* Chrome, FF 4.0 support box-shadow without vendor prefix.
   * Safari 3/4/5 and FF 3.5/3.6 require vendor prefix.
   * No support in FF 3.0, IE 6/7/8, Opera 10.0, iPhone 3.
   * change the offsets, blur and color to suit your design.
   */
  -moz-box-shadow: 2px 2px 3px #666;
  -webkit-box-shadow: 2px 2px 3px #666;
  box-shadow: 2px 2px 3px #666;
  
  /* using a bigger font for demo purposes so the box isn't too small */
  font-size: 20px;
  
  /* with a big radius/font there needs to be padding left and right
   * otherwise the text is too close to the radius.
   * on a smaller radius/font it may not be necessary
   */
  padding: 4px 7px;
  
  /* only needed for webkit browsers which show a rectangular outline;
   * others do not do outline when radius used.
   * android browser still displays a big outline
   */
  outline: 0;

  /* this is needed for iOS devices otherwise a shadow/line appears at the
   * top of the input. depending on the ratio of radius to height it will
   * go all the way across the full width of the input and look really messy.
   * ensure the radius is no more than half the full height of the input, 
   * and the following is set, and everything will render well in iOS.
   */
  -webkit-appearance: none;
  
}

input#identity.rounded:focus {
  
  /* supported IE8+ and all other browsers tested.
   * optional, but gives the input focues when selected.
   * change to a color that suits your design.
   */
  border-color: #339933;
  
}
