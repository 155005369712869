#header {
    padding: 20px 0 12px 0;
}

#header .site-branding{
    float: left;
    display: block;
    position: relative;
}

#header-wrap {
    border-top: 2px solid #ff8000;
    border-bottom: 2px solid #e4e4e4;
    min-height: 134px;
}

#header-wrap #header {
    min-height: 134px;
}

#header-wrap.reduce-header{
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    min-height: initial;
    max-height: 82px;
}


#header-wrap.reduce-header #header {
    padding: 9px 0 9px 0;
    min-height: 82px;
}

#header-wrap.reduce-header .second_menu{
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    -webkit-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -moz-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -ms-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -o-transition: height 0s 500ms, opacity 300ms ease-in-out;
    transition: height 0s 500ms, opacity 300ms ease-in-out;
}

#header-wrap.reduce-header .top-header{
    opacity: 0;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -moz-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -ms-transition: height 0s 500ms, opacity 300ms ease-in-out;
    -o-transition: height 0s 500ms, opacity 300ms ease-in-out;
    transition: height 0s 500ms, opacity 300ms ease-in-out;
}

#header-wrap.reduce-header #site-navigation-wrap {
    top: 32px;
    height: auto;
    margin-top: 0px;
}

#header-wrap.reduce-header #logo img {
    max-height: 60px;
}

#main.reduce-header-main {
    margin-top: 145px;
}

/*Second Menu */
.second_menu{
    float: left;
    display: block;
    position: relative;
    margin-top: 15px;
    -webkit-transition: height 0s, opacity 300ms ease-in-out;
    -moz-transition: height 0s, opacity 300ms ease-in-out;
    -ms-transition: height 0s, opacity 300ms ease-in-out;
    -o-transition: height 0s, opacity 300ms ease-in-out;
    transition: height 0s, opacity 300ms ease-in-out;
    opacity: 1;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li{
    display: inline;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li a:after{
    content: " | ";
    color: #b6b6b6;
    margin: 0px 5px;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li >a{
    color: #b6b6b6;
    font-size: 13px;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li >a:hover{
    color: #002CB2;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li:first-child a{
    color: #1971ff;
    font-weight: 600;
}

.second_menu .menu-menu-secundario-container #menu-menu-secundario > li:last-child a:after{
    content: "";
}


/* Main Navigation */
#site-navigation-wrap{
    bottom: 17px;
    height: 16px;
    margin-top: 0px;
    top: inherit;
}

#site-navigation .dropdown-menu a {
    color: #06F;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
}

#site-navigation .dropdown-menu > li > a:hover,
#site-navigation .dropdown-menu > li.sfHover > a,
#site-navigation .dropdown-menu > .current-menu-item > a,
#site-navigation .dropdown-menu > .current-menu-item > a:hover {
    color: #002CB2;
}

#site-navigation .dropdown-menu > li > a:hover:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 2px;
    margin-top: 13px;
    -webkit-transition: max-width 0.4s;
    transition: max-width 0.4s;
    max-width: 100%;
    background: #ff7f00;
}

#site-navigation .dropdown-menu > li > a:hover,
#site-navigation .dropdown-menu > li > a:hover .fa-angle-down{
  color: #ff7f00;
}

#site-navigation .dropdown-menu li{
    height: auto;
    line-height: inherit;
}
/* Footer */
#footer-wrap {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffcf53+0,fe9a2a+100 */
    background: #fff !important; /* Old browsers */
    padding: 60px 0 0 !important;
	font-family: 'Open Sans',Helvetica,Arial;
}

#copyright-wrap {
    background: #ededed !important;
    color: #0029d4 !important;
    padding: 30px 0 15px 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

#footer-wrap a {
    color: #0029d4 !important;
    font-size: 14px !important;
    line-height: 30px !important;
}

#footer-wrap a:hover { 
    color: #002CB2 !important;
    text-decoration: none !important;
}

#footer-wrap .logo-footer{
    max-width: 173px !important;
}

#footer-widgets{
    position: relative !important;
}

#footer-widgets .widget-title {
    font-size: 20px !important;
    margin: 0 23px 10px !important;
    font-weight: 500 !important;
    line-height: 1.2em !important;
    color: #052490 !important;
}

#footer-widgets .widget_nav_menu ul > li {
    border-bottom: none !important;
}

#footer-widgets .widget_nav_menu ul > li:before {

}

#footer-widgets .widget_nav_menu ul > li:first-child {
    border-top: none !important;
}

#footer-widgets .menu-contactos-container,
#footer-widgets .menu-contactos-container h2{
  
}

#footer-widgets .menu-contactos-container h2{
    font-size: 14px !important;
    color: #0029d4 !important;
    font-weight: 400 !important;
    line-height: 28px !important;
}

#footer-widgets .menu-contactos-container ul li{
    margin-bottom: -22px !important;
}

#footer-widgets .menu-contactos-container ul li img{
    width: 27px !important;
}

#footer-widgets .span_1_of_3.col-2 {
    padding-left: 6% !important;
    padding-right: 5px !important;
}

#footer-widgets .footer-widget{
    margin-bottom: 0px !important;
}

#footer-widgets .footer-widget.widget_nav_menu{
    margin-bottom: 50px !important;
}

#footer-widgets .footer-widget .logos-corporativos{
    text-align: right !important;
    position: absolute !important;
    bottom: 30px !important;
    right: 90px !important;
}

#footer-widgets .footer-widget .logos-corporativos{
    margin-right: 15px !important;
}

#footer-widgets .footer-widget .logos-corporativos img.seal-seflorida{
    height: 70px !important;
}

#footer-widgets .footer-widget .logos-corporativos img.authorize{
    height: 70px !important;
}

#copyright-wrap .footer-menu  #menu-footer-menu-ingles > li,
#copyright-wrap .footer-menu  #menu-footer-menu > li{
    display: inline !important;
    list-style:none !important;
}

#copyright-wrap .footer-menu #menu-footer-menu > li >a,
#copyright-wrap .footer-menu #menu-footer-menu-ingles > li >a {
    color: #0029d4 !important;
    font-size: 11px !important;
}

#copyright-wrap .footer-menu #menu-footer-menu > li:last-child > a:after,
#copyright-wrap .footer-menu #menu-footer-menu-ingles > li:last-child > a:after{
    display: none !important;
}

#copyright-wrap .col{
    margin-bottom: 0px !important;
}

#copyright-wrap .footer-menu,
#copyright-wrap .copyright-content{
    text-align: right !important;
}

#copyright-wrap .footer-menu  #menu-footer-menu-ingles  >li a:after,
#copyright-wrap .footer-menu  #menu-footer-menu  >li a:after{
    content: " | " !important;
    color: #18359b !important;
    margin: 0px 5px !important;
}

#operators-band{

}

#copyright-wrap .sponsors-logos{
    float: left !important;
    position: relative !important;
    display: block !important;
}

#copyright-wrap .sponsors-logos .widget_sp_image{
    display: inline-block !important;
    position: relative !important;
    margin-right: 10px !important;
    max-width: 145px !important;
    vertical-align: middle !important;
}

div .sponsors-logos > div{
    display: inline-block !important;
    padding-right: 10px !important;
    margin-bottom: 0px !important;
}

#copyright-wrap .footer-menu > div {
    margin-bottom: 10px !important;
}

#operators-band{
   background-color: #f2f3fd !important;
}

#operators-band .sow-image-container img{
    max-width: 90px !important;
    margin: auto !important;
}

ul#jj_sl_navigation{position:relative;margin:0;padding:0}ul#jj_sl_navigation li{padding:0;display:inline-block;margin:15px 10px 15px 0;position:relative}ul#jj_sl_navigation li a{display:inline-block;width:25px;height:25px;margin:0;background-repeat:no-repeat;text-decoration:none;font-size:12px;font-weight:400;text-transform:inherit;text-align:left;line-height:28px;background-size:cover;background-repeat:no-repeat;background-position:inherit}ul.jj_sl_navigation li a:focus{outline:0;border:0}ul#jj_sl_navigation li a .jj_social_text{display:inline-block;width:130px}ul#jj_sl_navigation .jj_sl_facebook a{background-image:url(//www.ringvoz.com/wp-content/themes/ringvoz/images/facebook-white.png);margin-left:-5px}ul#jj_sl_navigation .jj_sl_twitter a{background-image:url(//www.ringvoz.com/wp-content/themes/ringvoz/images/twitter-white.png)}ul#jj_sl_navigation .jj_sl_google a{background-image:url(//www.ringvoz.com/wp-content/themes/ringvoz/images/google-white.png)}ul#jj_sl_navigation .jj_sl_pinterest a{background-image:url(//www.ringvoz.com/wp-content/themes/ringvoz/images/pinterest-white.png)}ul#jj_sl_navigation .jj_sl_custom1 a{background-image:url(//www.ringvoz.com/wp-content/themes/ringvoz/images/icon-campana.png)}

.col {
    display: block;
    float: left;
    margin: 0 0 2% 1.6%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.col-1, .col.first {
    clear: both;
    margin-left: 0;
}

.span_1_of_4.col-1 {
    padding-left: 0;
}

.span_1_of_4 {
    width: 23.8%;
}

.clr:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

.col:after, .clr:after, .group:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    zoom: 1;
}


/* Language Switcher */
.top-header{
    position: absolute;
    right: 0;
    top: 32px;
    height: auto;
    margin-top: 0px;
    -webkit-transition: height 0s, opacity 300ms ease-in-out;
    -moz-transition: height 0s, opacity 300ms ease-in-out;
    -ms-transition: height 0s, opacity 300ms ease-in-out;
    -o-transition: height 0s, opacity 300ms ease-in-out;
    transition: height 0s, opacity 300ms ease-in-out;
    opacity: 1;
}

.top-header .widget_polylang{

}

.top-header .top-header-widget{
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 13px;
    font-weight: 600;
}

.top-header .top-header-widget:first-child .textwidget:after{
    content: " | ";
    color: #b6b6b6;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 10px;
    display: inline;
}

.top-header .top-header-widget:last-child{
    margin-right: 0px;
}

.top-header .widget_polylang ul li{
    float: left;
    border: none;
    margin-right: 3px;
}

.top-header .widget_polylang ul li a{
    color: #b6b6b6;
}

.top-header .widget_polylang ul li.current-lang a{
    color: #1971ff ;
}

.top-header .widget_polylang ul li:last-child{
    margin-right: 0px;
}

.top-header .widget_polylang ul li:last-child a:after {
    content: "";
}

.top-header .widget_polylang ul li a:after {
    content: " / ";
    color: #b6b6b6;
}

.top-header .widget_polylang ul li a:hover{
    color: #002CB2;
}

.top-header .widget_polylang ul li.current-lang a:hover{
    color: #b6b6b6;
}

.top-header ul.account{

}

.top-header ul.account li{
    float: left;
    border: none;
    margin-right: 10px;
}

.top-header ul.account li:last-child{
    margin-right: 0px;
}

/* Header Menu */

#site-navigation .dropdown-menu ul{
    background: #FFF;
    font-size: 1em;
    box-shadow: 0 0 0 1px #ddd;
    margin-top: 6px;
    margin-left: -10px;
    padding: 15px 0px;
    border-top: 2px solid #ff7f00;
}

#site-navigation .dropdown-menu ul li a {
    display: block;
    height: auto;
    line-height: 1.6em;
    padding: 6px 25px;
    margin: 0px;
    border: 0px;
    text-transform: none;
    font-weight: 500;
    font-size: 15px;
}

#site-navigation .dropdown-menu ul li > a:hover {
    color: #002CB2;
}

#site-navigation .dropdown-menu > .current-menu-item > a:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 2px;
    margin-top: 13px;
    -webkit-transition: max-width 0.4s;
    transition: max-width 0.4s;
    max-width: 100%;
    background: #ff7f00;
}