.logoContainer {
    margin-top: 20px;
}

.visible-md-inline {
    display: inline !important;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

@media (max-width: 425px) {

    .container > .navbar-collapse {
        top: 39px;
        right: 0;
    }

    .langs {
        position: relative !important;
        right: 65px !important;
        top: 0px !important;
    }

    .col-xs-centered {
        text-align: center;
    }

}

@media (min-width: 768px) {
    .container > .navbar-collapse {
        left: 40px;
        top: 12px !important;
    }
}

@media (max-width: 1024px) {
    .langs {
        position: absolute;
        right: 0px;
        top: 50px;
    }
}

@media (min-width: 1024px) {
    .container > .navbar-collapse {
        padding: 0px;
        margin-top: 0;
        position: relative;
        left: 0px;
    }
}

@media (max-width: 768px) {
    #logoimg {
        left: 20px;
    }

    /*
    .langs {
        position: relative;
        right: 65px;
        top: 0;
    }
    */
    .langs {
        position: absolute;
        right: 65px;
        top: 50px;
    }

    .container > .navbar-collapse > li {

    }

    #navbar {
        min-height: 115px;
    }

    .container > .navbar-collapse {
        border: 0;
        margin: 0;
        right: 0;
        overflow: visible !important;
        top: 35px;
        z-index: 100;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #000 !important;
    }

    .navbar-nav .open .dropdown-menu {
        top: 0 !important;
        color: #000 !important;
        z-index: 1000001 !important;
        position: relative !important;
        background-color: #fff !important;
        border: 1px solid #ccc !important;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        border-radius: 4px !important;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;
    }

    .logoContainer {
        height: 80px;
    }
}

@media (max-width: 991px) {
    .img-responsive {
        display: inline !important;
    }

    .well:hover {
        width: auto !important;
    }

    .well {
        display: inline-block !important;
    }

    .textSeleccionaRecarga {
        margin-bottom: 10px;
    }
}

